import React, { lazy } from 'react';
import { authMenu, dashboardMenu, loansMenu, profileMenu } from '../menu';

const AUTH = {
	LOGIN: lazy(() => import('../pages/auth/Login')),
	CONFIRM_SIGNUP: lazy(() => import('../pages/auth/ConfirmSignUp')),
	FORGET_PASSWORD: lazy(() => import('../pages/auth/forget/Forget')),
	CONFIRM_RESET: lazy(() => import('../pages/auth/forget/ConfirmForget')),
	RESET: lazy(() => import('../pages/auth/forget/ResetPassword')),
};

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
};

const PROFILE = {
	PROFILE: lazy(() => import('../pages/profile/Profile')),
};

const LOANS_MENU = {
	PUBLIC_LOANS: {
		LOAN_INDEX: lazy(() => import('../pages/Loans/Public/PublicIndex')),
		BOOK_LOAN: lazy(() => import('../pages/Loans/Public/PublicCreate')),
		SINGLE_LOAN: lazy(() => import('../pages/Loans/Public/SingleLoan')),
		BOOK_TOPUP: lazy(() => import('../pages/Loans/Public/BookTopup')),
	},
	PRIVATE_LOANS: {
		LOAN_INDEX: lazy(() => import('../pages/Loans/Private/PrivateIndex')),
		BOOK_LOAN: lazy(() => import('../pages/Loans/Private/PrivateCreate')),
		SINGLE_LOAN: lazy(() => import('../pages/Loans/Private/SingleLoan')),
		BOOK_TOPUP: lazy(() => import('../pages/Loans/Private/BookTopup')),
	},
};

const presentation = [
	/**
	 * Auth
	 */
	{
		path: authMenu.login.path,
		element: <AUTH.LOGIN />,
		exact: true,
		isSecured: false,
	},
	{
		path: authMenu.confirmSignUp.path,
		element: <AUTH.CONFIRM_SIGNUP />,
		exact: true,
		isSecured: false,
	},
	{
		path: authMenu.forgetGroup.subMenu.forgetPassword.path,
		element: <AUTH.FORGET_PASSWORD />,
		exact: true,
		isSecured: false,
	},
	{
		path: authMenu.forgetGroup.subMenu.confirmation.path,
		element: <AUTH.CONFIRM_RESET />,
		exact: true,
		isSecured: false,
	},
	{
		path: authMenu.forgetGroup.subMenu.resetPassword.path,
		element: <AUTH.RESET />,
		exact: true,
		isSecured: false,
	},
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
		isSecured: false,
	},
	/**
	 * Profile
	 */
	{
		path: profileMenu.profile.path,
		element: <PROFILE.PROFILE />,
		exact: true,
		isSecured: false,
	},
	/**
	 * PUBLIC LOANS
	 */
	{
		path: loansMenu.publicLoans.subMenu.loanHistory.path,
		element: <LOANS_MENU.PUBLIC_LOANS.LOAN_INDEX />,
		exact: true,
		isSecured: true,
	},
	{
		path: loansMenu.publicLoans.subMenu.bookLoan.path,
		element: <LOANS_MENU.PUBLIC_LOANS.BOOK_LOAN />,
		exact: true,
		isSecured: true,
	},
	{
		path: `${loansMenu.publicLoans.subMenu.bookLoan.path}/:id`,
		element: <LOANS_MENU.PUBLIC_LOANS.BOOK_LOAN />,
		exact: true,
		isSecured: true,
	},
	{
		path: `${loansMenu.publicLoans.subMenu.loanHistory.path}/:id`,
		element: <LOANS_MENU.PUBLIC_LOANS.SINGLE_LOAN />,
		exact: true,
		isSecured: true,
	},
	{
		path: `${loansMenu.publicLoans.subMenu.bookTopUp.path}/:id`,
		element: <LOANS_MENU.PUBLIC_LOANS.BOOK_TOPUP />,
		exact: true,
		isSecured: true,
	},
	/**
	 * PRIVATE LOANS
	 */
	{
		path: loansMenu.consumerLoans.subMenu.loanHistory.path,
		element: <LOANS_MENU.PRIVATE_LOANS.LOAN_INDEX />,
		exact: true,
		isSecured: true,
	},
	{
		path: loansMenu.consumerLoans.subMenu.bookLoan.path,
		element: <LOANS_MENU.PRIVATE_LOANS.BOOK_LOAN />,
		exact: true,
		isSecured: true,
	},
	{
		path: `${loansMenu.consumerLoans.subMenu.bookLoan.path}/:id`,
		element: <LOANS_MENU.PRIVATE_LOANS.BOOK_LOAN />,
		exact: true,
		isSecured: true,
	},
	{
		path: `${loansMenu.consumerLoans.subMenu.loanHistory.path}/:id`,
		element: <LOANS_MENU.PRIVATE_LOANS.SINGLE_LOAN />,
		exact: true,
		isSecured: true,
	},
	{
		path: `${loansMenu.consumerLoans.subMenu.bookTopUp.path}/:id`,
		element: <LOANS_MENU.PRIVATE_LOANS.BOOK_TOPUP />,
		exact: true,
		isSecured: true,
	},
];
const contents = [...presentation];

export default contents;
