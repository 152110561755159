/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { Navigate } from 'react-router-dom';
import { dashboardMenu, profileMenu } from '../menu';

const SecuredRoute = ({ userData, children }) => {
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{userData.details === null ? (
				<Navigate
					to={profileMenu.profile.path}
					state={{ warning: 'Please complete your account registration to proceed' }}
				/>
			) : userData.status === 'pending' ? (
				<Navigate
					to={dashboardMenu.dashboard.path}
					state={{ warning: 'Your account is pending approval' }}
				/>
			) : userData.status === 'declined' ? (
				<Navigate
					to={dashboardMenu.dashboard.path}
					state={{ warning: 'Your account does not meet up with our requirement' }}
				/>
			) : (
				userData.details && userData.status === 'approved' && children
			)}
		</>
	);
};

export default SecuredRoute;
