import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import contents from '../../routes/contentRoutes';
import SecuredRoute from '../../App/SecuredRoute';
import UseToken from '../../helpers/authService';
import { authMenu } from '../../menu';

const PAGE_404 = lazy(() => import('../../pages/Page404'));
const ContentRoutes = () => {
	const userData = JSON.parse(UseToken().getUserData());

	return (
		<Routes>
			{contents.map((page) =>
				page.isSecured ? (
					<Route
						key={page.path}
						path={page.path}
						exact={page.exact}
						element={
							userData ? (
								// eslint-disable-next-line react/no-children-prop
								<SecuredRoute userData={userData.user} children={page.element} />
							) : (
								<Navigate to={authMenu.login.path} />
							)
						}
					/>
				) : (
					<Route
						key={page.path}
						path={page.path}
						exact={page.exact}
						element={page.element}
					/>
				),
			)}
			<Route path='*' element={<PAGE_404 />} />
		</Routes>
	);
};

export default ContentRoutes;
