import React, { useContext, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/bootstrap/Button';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Alert, { AlertHeading } from '../../../components/bootstrap/Alert';
import { HeaderRight } from '../../../layout/Header/Header';
import ThemeContext from '../../../contexts/themeContext';
import Popovers from '../../../components/bootstrap/Popovers';
import UseToken from '../../../helpers/authService';
import { capitalizeFirstLetter } from '../../../helpers/number_utils';

const CommonHeaderRight = ({ beforeChildren, afterChildren }) => {
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const styledBtn = {
		color: 'light',
		hoverShadow: 'default',
		isLight: true,
		size: 'lg',
	};

	const [offcanvasStatus, setOffcanvasStatus] = useState(false);

	const { i18n } = useTranslation();

	const userData = JSON.parse(UseToken().getUserData());

	const colorArr = ['info', 'primary', 'success', 'info', 'secondary', 'warning'];

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	return (
		<HeaderRight>
			<div className='row g-3'>
				{beforeChildren}
				{/*	Full Screen */}
				<div className='col-auto'>
					<Popovers trigger='hover' desc='Fullscreen'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
							onClick={() => setFullScreenStatus(!fullScreenStatus)}
							aria-label='Toggle dark mode'
						/>
					</Popovers>
				</div>

				{/*	Notifications */}
				<div className='col-auto'>
					<Button
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...styledBtn}
						icon='Notifications'
						onClick={() => setOffcanvasStatus(true)}
						aria-label='Notifications'
					/>
				</div>

				{afterChildren}
			</div>

			{userData && (
				<OffCanvas
					id='notificationCanvas'
					titleId='offcanvasExampleLabel'
					placement='end'
					isOpen={offcanvasStatus}
					setOpen={setOffcanvasStatus}>
					<OffCanvasHeader setOpen={setOffcanvasStatus}>
						<OffCanvasTitle id='offcanvasExampleLabel'>Notifications</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						{userData.unread_notifications.map((item, i) => {
							return (
								<Alert
									key={`alert-${i + 1}`}
									icon={capitalizeFirstLetter(item.data.icon)}
									isLight
									color={colorArr[Math.floor(Math.random() * colorArr.length)]}
									className='flex-nowrap shadow-3d-primary'
									borderWidth={0}>
									<AlertHeading tag='h2' className='h4'>
										{item.data.title}
									</AlertHeading>
									<span>
										{item.data.body.substr(0, 55)}......{' '}
										<Link
											to='/'
											className='text-decoration-underline  fw-bold display-10 text-dark'>
											Read more
										</Link>
									</span>
								</Alert>
							);
						})}
					</OffCanvasBody>
				</OffCanvas>
			)}
		</HeaderRight>
	);
};
CommonHeaderRight.propTypes = {
	beforeChildren: PropTypes.node,
	afterChildren: PropTypes.node,
};
CommonHeaderRight.defaultProps = {
	beforeChildren: null,
	afterChildren: null,
};

export default CommonHeaderRight;
